import * as React from "react"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
  <div class="container">
    <div class="intro_bg"></div>
    <div class="page_title">
      <h1>The Future of Decentralized Finance</h1>
    </div>
      
      <svg class="page_title_svg" width="1138" height="197" viewBox="0 0 1138 197" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>The Future of Decentralized Finance</title>
          <g clip-path="url(#clip0_18069_205260)">
          <path d="M251.43 57.334C245.153 57.3804 238.879 57.3876 232.605 57.3626C232.605 52.9432 232.605 48.5273 232.605 44.115C250.238 44.19 267.882 43.9758 285.532 43.5117C285.532 47.9204 285.532 52.3363 285.532 56.7593C279.251 56.9235 272.974 57.0591 266.697 57.1591C266.694 76.7038 266.686 96.3485 266.683 116.093C261.594 116.175 256.504 116.236 251.415 116.272C251.419 96.527 251.426 76.8787 251.43 57.334Z" fill="black"/>
          <path d="M291.238 43.356C296.331 43.2061 301.424 43.0383 306.517 42.8527C306.517 52.0057 306.513 61.1801 306.509 70.3759C312.212 70.1653 317.919 69.9297 323.622 69.6727C323.622 60.4769 323.622 51.306 323.625 42.153C328.718 41.9245 333.811 41.6782 338.904 41.4141C338.9 65.3139 338.896 89.3673 338.889 113.571C333.796 113.835 328.704 114.085 323.607 114.317C323.607 103.854 323.611 93.4155 323.615 83.0095C317.912 83.2665 312.205 83.5021 306.502 83.7127C306.502 94.1223 306.499 104.557 306.495 115.024C301.402 115.213 296.31 115.384 291.217 115.534C291.22 91.3236 291.228 67.2666 291.231 43.356H291.238Z" fill="black"/>
          <path d="M348.683 40.8878C363.962 40.0382 379.24 39.0422 394.514 37.9355C394.514 42.1801 394.514 46.4281 394.514 50.6798C384.228 51.4259 373.942 52.122 363.656 52.7574C363.656 58.13 363.656 63.5132 363.656 68.9001C372.755 68.3361 381.853 67.7256 390.951 67.0759C390.951 71.1562 390.951 75.2365 390.951 79.3239C381.853 79.9772 372.755 80.5876 363.656 81.1517C363.656 87.1597 363.656 93.1784 363.656 99.2042C373.942 98.5652 384.228 97.8655 394.514 97.1159C394.514 101.425 394.514 105.741 394.514 110.06C379.236 111.177 363.958 112.18 348.68 113.041C348.683 88.8375 348.687 64.7877 348.69 40.8878H348.683Z" fill="black"/>
          <path d="M434.213 34.8229C450.155 33.4842 466.09 32.0527 482.021 30.5605C482.021 35.1263 482.021 39.6957 482.021 44.2758C471.107 45.3003 460.19 46.2963 449.272 47.253C449.272 52.5577 449.272 57.8696 449.272 63.1886C459.31 62.3069 469.345 61.393 479.376 60.4541C479.376 65.0556 479.376 69.6643 479.376 74.2765C469.41 75.2117 459.443 76.122 449.473 77.0002C449.473 86.5102 449.473 96.0452 449.473 105.602C444.384 106.051 439.298 106.49 434.209 106.922C434.209 82.7405 434.213 58.7085 434.216 34.8265L434.213 34.8229Z" fill="black"/>
          <path d="M489.131 80.6768C489.131 63.6738 489.131 46.7457 489.131 29.8926C494.213 29.4107 499.295 28.9217 504.377 28.4326C504.377 45.25 504.377 62.1423 504.377 79.1096C504.377 85.1569 507.732 88.2055 513.521 87.6343C519.21 87.0739 522.662 83.3541 522.662 77.3069C522.662 60.3431 522.662 43.4544 522.662 26.6406C527.741 26.1408 532.819 25.6339 537.894 25.127C537.894 41.9693 537.894 58.8867 537.894 75.8825C537.887 89.5692 528.545 99.0756 513.518 100.564C498.483 102.049 489.134 94.4777 489.127 80.6803L489.131 80.6768Z" fill="black"/>
          <path d="M561.747 35.9407C555.487 36.5725 549.232 37.2044 542.973 37.8291C542.973 33.4204 542.973 29.0152 542.973 24.6172C560.566 22.8573 578.148 21.0724 595.723 19.3125C595.723 23.7069 595.723 28.1085 595.723 32.5172C589.471 33.1455 583.219 33.7738 576.964 34.4057C576.964 53.8933 576.964 73.4772 576.964 93.1647C571.893 93.6788 566.821 94.1964 561.747 94.714C561.747 75.023 561.747 55.4318 561.747 35.9443V35.9407Z" fill="black"/>
          <path d="M600.791 69.5211C600.791 52.5431 600.791 35.6401 600.791 18.8084C605.859 18.3015 610.926 17.7981 615.99 17.2984C615.99 34.0908 615.99 50.9581 615.99 67.9004C615.99 73.9406 619.335 76.9749 625.11 76.4073C630.784 75.8468 634.226 72.1449 634.226 66.1084C634.226 49.1696 634.226 32.3058 634.226 15.517C639.29 15.028 644.35 14.5425 649.414 14.0605C649.414 30.8779 649.414 47.7703 649.414 64.7411C649.411 78.41 640.094 87.8378 625.11 89.3193C610.122 90.8008 600.795 83.3006 600.791 69.5211Z" fill="black"/>
          <path d="M657.002 13.3483C664.93 12.6058 672.858 11.8811 680.782 11.1743C696.466 9.77847 706.58 17.35 706.576 30.6583C706.576 39.9862 701.516 48.2753 693.634 52.2092C698.293 61.6764 702.948 71.1792 707.592 80.7142C702.097 81.1747 696.602 81.6423 691.108 82.1243C687.131 73.339 683.155 64.5822 679.167 55.854C676.806 56.0646 674.444 56.2788 672.083 56.4966C672.083 65.5853 672.083 74.6991 672.083 83.8306C667.058 84.2911 662.03 84.7588 657.002 85.2336C657.002 61.1195 657.002 37.1553 656.998 13.3447L657.002 13.3483ZM680.786 42.9063C687.461 42.3102 691.406 38.5297 691.406 32.6395C691.406 26.5637 687.461 23.2795 680.782 23.8757C677.882 24.1327 674.982 24.3968 672.083 24.661C672.083 30.9939 672.083 37.341 672.083 43.6952C674.982 43.4311 677.882 43.1669 680.782 42.9099L680.786 42.9063Z" fill="black"/>
          <path d="M714.156 8.33246C729.319 7.10444 744.479 5.96924 759.639 4.95898C759.639 9.18564 759.639 13.4123 759.639 17.6497C749.432 18.3315 739.225 19.0669 729.018 19.8522C729.018 25.2034 729.018 30.5617 729.018 35.9271C738.047 35.231 747.077 34.5741 756.104 33.9566C756.104 38.019 756.104 42.0814 756.104 46.151C747.074 46.7686 738.047 47.4255 729.018 48.1251C729.018 54.1081 729.018 60.0983 729.018 66.0991C739.225 65.3102 749.435 64.5677 759.642 63.8823C759.642 68.1732 759.642 72.4677 759.642 76.7693C744.483 77.7903 729.323 78.9326 714.156 80.1749C714.156 56.0751 714.148 32.1288 714.148 8.33246H714.156Z" fill="black"/>
          <path d="M798.037 53.6831C798.037 43.4948 798.033 33.3316 798.033 23.1969C798.026 10.2956 807.418 1.19969 822.277 0.592822C837.135 -0.00690669 846.535 8.33931 846.531 21.237C846.531 31.3682 846.535 41.5278 846.538 51.7125C846.538 64.7709 837.139 73.7311 822.291 74.3344C807.443 74.9484 798.048 66.7414 798.037 53.6795V53.6831ZM831.378 52.2087C831.378 42.0205 831.375 31.8608 831.371 21.7297C831.371 16.0572 828.439 13.0265 822.277 13.2764C816.111 13.5298 813.186 16.7926 813.186 22.4686C813.186 32.6033 813.189 42.763 813.193 52.9512C813.193 58.6844 816.226 61.7044 822.287 61.4545C828.349 61.2082 831.378 57.9383 831.378 52.2087Z" fill="black"/>
          <path d="M854.611 0.518178C870.445 0.136209 886.283 -0.0422819 902.132 0.0112652C902.132 4.5592 902.132 9.11071 902.132 13.6694C891.272 13.6301 880.419 13.7051 869.57 13.8764C869.57 19.1562 869.57 24.4466 869.573 29.7442C879.547 29.5836 889.524 29.5122 899.505 29.53C899.505 34.1137 899.505 38.7009 899.505 43.2952C889.592 43.2774 879.68 43.3488 869.774 43.5058C869.774 52.973 869.778 62.4651 869.781 71.9822C864.728 72.0643 859.671 72.1643 854.618 72.2892C854.614 48.2144 854.607 24.2931 854.604 0.521748L854.611 0.518178Z" fill="black"/>
          <path d="M0.0323008 100.947C7.92805 102.579 15.8346 104.11 23.7519 105.542C38.8973 108.273 49.0506 119.06 49.0362 133.208C49.0362 141.954 49.029 150.721 49.0254 159.51C49.0147 173.779 38.8543 181.821 23.7195 179.069C15.8023 177.623 7.89575 176.081 0 174.432C0.0107669 149.782 0.0215339 125.286 0.0323008 100.947ZM23.7267 165.365C29.7419 166.46 33.9553 163.016 33.9625 157.05C33.9661 148.265 33.9697 139.501 33.9733 130.755C33.9768 124.836 29.7634 120.121 23.7483 119.032C20.8412 118.507 17.9377 117.965 15.0342 117.415C15.027 132.794 15.0199 148.233 15.0163 163.737C17.9198 164.294 20.8268 164.836 23.7303 165.365H23.7267Z" fill="black"/>
          <path d="M56.8852 110.957C71.9804 113.17 87.1043 115.048 102.253 116.619C102.253 120.952 102.253 125.286 102.25 129.631C92.0464 128.57 81.8573 127.371 71.6789 126.025C71.6789 131.508 71.6753 136.995 71.6717 142.493C80.6729 143.685 89.6848 144.763 98.7075 145.734C98.7075 149.897 98.7075 154.066 98.7039 158.239C89.6812 157.268 80.6693 156.19 71.6682 154.994C71.6682 161.124 71.6646 167.264 71.661 173.411C81.8393 174.767 92.0284 175.974 102.232 177.041C102.232 181.443 102.232 185.848 102.228 190.257C87.0756 188.668 71.9517 186.773 56.8564 184.542C56.8672 159.86 56.8744 135.332 56.8852 110.961V110.957Z" fill="black"/>
          <path d="M110.318 169.741C110.322 159.289 110.326 148.865 110.329 138.47C110.329 125.119 120.134 117.422 135.025 118.55C149.919 119.668 159.757 128.864 159.746 142.222C154.675 141.94 149.607 141.626 144.539 141.283C144.543 135.457 141.097 132.023 135.021 131.566C128.945 131.105 125.503 134.015 125.503 139.841C125.503 150.24 125.496 160.663 125.493 171.119C125.493 176.999 128.931 180.547 135.007 181.011C141.083 181.472 144.528 178.448 144.532 172.569C149.6 172.915 154.667 173.229 159.739 173.515C159.728 187.005 149.887 195.366 135.003 194.238C120.123 193.099 110.326 183.132 110.322 169.749L110.318 169.741Z" fill="black"/>
          <path d="M168.068 121.605C183.289 122.355 198.528 122.844 213.774 123.098C213.774 127.435 213.774 131.772 213.77 136.12C203.502 135.949 193.241 135.674 182.984 135.281C182.984 140.768 182.984 146.266 182.981 151.77C192.054 152.117 201.13 152.374 210.21 152.549C210.21 156.715 210.21 160.888 210.21 165.064C201.13 164.889 192.054 164.632 182.981 164.286C182.981 170.423 182.981 176.57 182.973 182.728C193.231 183.124 203.492 183.402 213.76 183.574C213.76 187.979 213.76 192.388 213.756 196.8C198.507 196.543 183.268 196.054 168.047 195.294C168.054 170.576 168.061 146.016 168.068 121.613V121.605Z" fill="black"/>
          <path d="M220.485 123.193C225.876 123.258 231.266 123.297 236.657 123.304C243.756 138.515 250.841 153.737 257.915 168.973C257.918 153.655 257.922 138.401 257.925 123.204C262.677 123.151 267.425 123.079 272.177 122.986C272.17 147.397 272.166 171.961 272.159 196.682C267.102 196.778 262.045 196.856 256.989 196.91C249.642 181.56 242.288 166.224 234.916 150.899C234.913 166.206 234.909 181.57 234.906 196.999C230.089 196.985 225.276 196.949 220.46 196.889C220.467 172.168 220.474 147.6 220.481 123.19L220.485 123.193Z" fill="black"/>
          <path d="M294.68 135.942C288.4 136.138 282.119 136.306 275.842 136.438C275.842 131.926 275.842 127.417 275.842 122.916C293.489 122.541 311.147 121.909 328.804 121.066C328.804 125.568 328.804 130.073 328.804 134.585C322.524 134.885 316.239 135.16 309.959 135.403C309.955 155.354 309.951 175.406 309.948 195.565C304.855 195.765 299.762 195.947 294.67 196.107C294.673 175.949 294.677 155.893 294.684 135.938L294.68 135.942Z" fill="black"/>
          <path d="M334.406 120.791C342.385 120.392 350.366 119.949 358.345 119.467C374.136 118.51 384.322 126.375 384.315 140.026C384.315 149.582 379.215 158.017 371.276 161.873C375.967 171.686 380.65 181.51 385.327 191.352C379.792 191.745 374.258 192.12 368.724 192.48C364.722 183.381 360.717 174.292 356.708 165.214C354.332 165.357 351.953 165.496 349.577 165.632C349.577 174.949 349.577 184.288 349.573 193.648C344.513 193.937 339.452 194.212 334.396 194.469C334.399 169.755 334.403 145.198 334.41 120.795L334.406 120.791ZM358.341 151.988C365.063 151.581 369.036 147.79 369.04 141.757C369.04 135.528 365.071 132.076 358.345 132.483C355.423 132.657 352.505 132.829 349.584 132.997C349.584 139.486 349.584 145.991 349.584 152.502C352.505 152.334 355.423 152.163 358.345 151.988H358.341Z" fill="black"/>
          <path d="M404.581 116.293C411.031 115.8 417.476 115.286 423.926 114.758C430.085 138.633 436.222 162.647 442.341 186.804C437.151 187.257 431.962 187.703 426.768 188.139C425.584 182.641 424.396 177.155 423.208 171.671C417.304 172.157 411.4 172.632 405.493 173.089C404.305 178.761 403.113 184.441 401.925 190.127C396.732 190.523 391.539 190.909 386.346 191.277C392.407 166.142 398.487 141.149 404.585 116.293H404.581ZM420.559 159.745C418.492 149.485 416.425 139.25 414.354 129.044C412.247 139.586 410.141 150.152 408.038 160.744C412.211 160.419 416.385 160.087 420.559 159.745Z" fill="black"/>
          <path d="M451.5 112.399C456.586 111.946 461.671 111.485 466.76 111.018C466.76 130.869 466.76 150.825 466.757 170.883C476.756 169.955 486.755 169.002 496.753 168.024C496.753 172.586 496.753 177.155 496.753 181.732C481.673 183.206 466.588 184.638 451.5 185.991C451.5 161.302 451.504 136.774 451.507 112.399H451.5Z" fill="black"/>
          <path d="M503.863 167.33C508.777 166.845 513.686 166.356 518.596 165.867C518.596 150.359 518.596 134.916 518.596 119.531C513.686 120.02 508.777 120.505 503.863 120.987C503.863 116.482 503.863 111.984 503.863 107.493C518.765 106.037 533.663 104.537 548.553 103.027C548.553 107.518 548.553 112.013 548.553 116.514C543.647 117.014 538.741 117.51 533.831 118.006C533.831 133.385 533.831 148.828 533.831 164.332C538.737 163.836 543.647 163.332 548.553 162.832C548.553 167.395 548.553 171.96 548.553 176.53C533.663 178.054 518.765 179.568 503.863 181.038C503.863 176.465 503.863 171.896 503.863 167.33Z" fill="black"/>
          <path d="M559.209 161.737C568.256 145.313 577.319 128.952 586.395 112.656C577.606 113.548 568.813 114.448 560.02 115.344C560.02 110.842 560.02 106.348 560.02 101.857C574.595 100.376 589.166 98.8871 603.73 97.4199C603.73 101.907 603.73 106.402 603.73 110.896C594.621 127.181 585.53 143.542 576.454 159.963C585.921 158.992 595.382 158.025 604.846 157.064C604.846 161.623 604.846 166.185 604.846 170.751C589.64 172.297 574.426 173.864 559.205 175.427C559.205 170.858 559.205 166.292 559.205 161.73L559.209 161.737Z" fill="black"/>
          <path d="M615.484 96.2449C630.68 94.7278 645.872 93.2427 661.057 91.8184C661.057 96.1378 661.057 100.461 661.057 104.787C650.832 105.748 640.607 106.737 630.379 107.743C630.379 113.212 630.379 118.688 630.379 124.172C639.426 123.279 648.474 122.401 657.515 121.544C657.515 125.696 657.515 129.848 657.515 134.01C648.471 134.867 639.426 135.748 630.379 136.641C630.379 142.756 630.379 148.882 630.379 155.015C640.607 154.001 650.836 153.008 661.061 152.041C661.061 156.428 661.061 160.819 661.061 165.214C645.876 166.652 630.684 168.152 615.488 169.683C615.488 145.048 615.488 120.57 615.488 96.2449H615.484Z" fill="black"/>
          <path d="M669.455 91.0378C677.447 90.2988 685.44 89.5813 693.433 88.8852C708.711 87.5536 718.925 96.0141 718.918 110.104C718.918 118.825 718.918 127.568 718.918 136.328C718.911 150.572 708.7 160.91 693.436 162.252C685.444 162.955 677.451 163.68 669.458 164.426C669.458 139.809 669.455 115.345 669.451 91.0378H669.455ZM693.44 148.58C699.509 148.048 703.755 143.543 703.755 137.592C703.755 128.828 703.755 120.085 703.755 111.364C703.755 105.46 699.505 101.822 693.436 102.351C690.504 102.608 687.568 102.865 684.636 103.129C684.636 118.479 684.636 133.89 684.64 149.365C687.572 149.101 690.508 148.84 693.44 148.583V148.58Z" fill="black"/>
          <path d="M756.11 83.9663C771.941 82.8811 787.769 81.9279 803.6 81.1426C803.6 85.7869 803.6 90.4348 803.6 95.0898C792.754 95.6289 781.908 96.2464 771.066 96.9319C771.066 102.326 771.066 107.731 771.066 113.139C781.036 112.507 791.006 111.936 800.973 111.425C800.973 116.105 800.973 120.792 800.973 125.483C791.071 125.99 781.169 126.558 771.267 127.186C771.267 136.857 771.267 146.556 771.27 156.276C766.217 156.598 761.164 156.933 756.11 157.283C756.11 132.687 756.103 108.248 756.1 83.9627L756.11 83.9663Z" fill="black"/>
          <path d="M812.702 140.341C817.587 140.116 822.468 139.909 827.352 139.72C827.352 124.266 827.345 108.873 827.342 93.5407C822.457 93.7299 817.576 93.9334 812.691 94.1583C812.691 89.671 812.691 85.1873 812.691 80.7108C827.51 80.0361 842.329 79.5185 857.155 79.1758C857.155 83.6523 857.155 88.1324 857.155 92.6197C852.271 92.7304 847.386 92.8624 842.501 93.0159C842.501 108.345 842.509 123.738 842.512 139.191C847.397 139.042 852.281 138.906 857.166 138.795C857.166 143.343 857.166 147.895 857.166 152.45C842.34 152.792 827.517 153.321 812.702 153.999C812.702 149.44 812.702 144.889 812.702 140.341Z" fill="black"/>
          <path d="M864.836 79.0173C870.194 78.9174 875.552 78.8424 880.911 78.7889C887.974 93.8285 895.022 108.975 902.06 124.229C902.057 109 902.053 93.832 902.05 78.7246C906.773 78.7425 911.492 78.7782 916.215 78.8388C916.223 103.106 916.23 127.531 916.233 152.109C911.205 152.045 906.181 152.006 901.156 151.991C893.849 136.634 886.527 121.384 879.195 106.244C879.199 121.462 879.202 136.738 879.206 152.077C874.418 152.127 869.634 152.198 864.85 152.288C864.843 127.71 864.839 103.285 864.832 79.0173H864.836Z" fill="black"/>
          <path d="M936.464 79.2285C942.881 79.3963 949.298 79.6069 955.719 79.8639C961.863 104.381 967.99 129.095 974.098 154.009C968.923 153.73 963.751 153.481 958.579 153.263C957.395 147.644 956.211 142.032 955.026 136.431C949.148 136.199 943.269 136.003 937.394 135.846C936.213 141.368 935.032 146.898 933.855 152.438C928.69 152.31 923.526 152.213 918.361 152.138C924.376 127.646 930.41 103.343 936.468 79.2321L936.464 79.2285ZM952.385 124.237C950.325 113.781 948.261 103.353 946.194 92.9616C944.101 103.218 942.009 113.502 939.92 123.823C944.073 123.94 948.229 124.08 952.385 124.237Z" fill="black"/>
          <path d="M976.205 80.834C981.585 81.1303 986.965 81.4623 992.348 81.8264C999.444 97.4229 1006.53 113.137 1013.61 128.976C1013.61 113.74 1013.6 98.5617 1013.6 83.4435C1018.34 83.8469 1023.09 84.2789 1027.84 84.7394C1027.85 109.028 1027.86 133.467 1027.87 158.067C1022.81 157.571 1017.75 157.11 1012.7 156.682C1005.36 140.71 998.001 124.871 990.633 109.157C990.636 124.379 990.644 139.661 990.647 155.008C985.838 154.686 981.032 154.39 976.23 154.122C976.223 129.537 976.216 105.109 976.209 80.834H976.205Z" fill="black"/>
          <path d="M1033.56 137.402C1033.56 126.992 1033.55 116.615 1033.55 106.262C1033.54 92.9932 1043.42 85.2681 1058.43 87.1173C1073.44 88.9807 1083.38 99.1726 1083.37 112.452C1078.26 111.699 1073.14 110.985 1068.03 110.303C1068.03 104.506 1064.56 100.833 1058.43 100.072C1052.3 99.3153 1048.84 102.121 1048.84 107.919C1048.84 118.275 1048.85 128.656 1048.85 139.065C1048.85 144.923 1052.32 148.55 1058.45 149.311C1064.57 150.078 1068.04 147.319 1068.04 141.457C1073.16 142.139 1078.27 142.856 1083.38 143.613C1083.38 157.071 1073.46 164.361 1058.45 162.48C1043.45 160.613 1033.57 150.746 1033.56 137.395L1033.56 137.402Z" fill="black"/>
          <path d="M1091.76 92.7477C1107.12 95.1252 1122.53 97.849 1137.97 100.948C1137.97 105.271 1137.97 109.597 1137.97 113.931C1127.58 111.843 1117.19 109.922 1106.83 108.166C1106.83 113.635 1106.83 119.111 1106.84 124.594C1116 126.154 1125.18 127.835 1134.38 129.652C1134.38 133.808 1134.38 137.967 1134.39 142.129C1125.19 140.308 1116.01 138.62 1106.84 137.06C1106.84 143.175 1106.85 149.301 1106.85 155.434C1117.22 157.204 1127.6 159.132 1138 161.235C1138 165.625 1138 170.023 1138 174.425C1122.56 171.298 1107.16 168.549 1091.79 166.147C1091.78 141.522 1091.77 117.055 1091.76 92.7441L1091.76 92.7477Z" fill="black"/>
        </g>
        <defs>
          <clipPath id="clip0_18069_205260">
            <rect width="1138" height="197" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <div class="news">
          <a class="news_post" href="https://tinymanorg.medium.com/revealing-the-tinyman-swap-widget-minutes-to-integrate-instant-best-rates-67c68140e029" target="_blank" rel="noreferrer">
            <div></div>
            <p>Revealing the Tinyman Swap Widget: Minutes to Integrate, Instant Best Rates</p>
          </a>
          <iframe title="tinyman swap widget" class="widget" src="https://hipo.github.io/ui098gh4350u9h435y-swap-widget/?platformName=&network=mainnet&themeVariables=eyJ0aGVtZSI6ImxpZ2h0IiwiY29udGFpbmVyQnV0dG9uQmciOiIjRjJGRTY3Iiwid2lkZ2V0QmciOiIjMEQ5RjYyIiwiaGVhZGVyQnV0dG9uQmciOiIjMEI4NzUzIiwiaGVhZGVyQnV0dG9uVGV4dCI6IiMwMDAwMDAiLCJoZWFkZXJUaXRsZSI6IiMwMDAwMDAiLCJjb250YWluZXJCdXR0b25UZXh0IjoiIzAwMDAwMCIsImlmcmFtZUJnIjoiI0Y4RjhGOCIsImJvcmRlclJhZGl1c1NpemUiOiJtZWRpdW0iLCJ0aXRsZSI6IlN3YXAiLCJzaG91bGREaXNwbGF5VGlueW1hbkxvZ28iOmZhbHNlfQ%3D%3D&assetIn=0&assetOut=31566704" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />
          <a class="news_post" href="https://app.tinyman.org/liquid-stake/stake" target="_blank" rel="noreferrer">
            <div></div>
            <p>Welcome to Liquid Staking! Support the security of the Algorand blockchain and earn rewards seamlessly.</p>
          </a>
      </div>
      <div class="intro_description">
        <p>Tinyman is a decentralized trading protocol which utilizes the fast and secure framework of the Algorand blockchain, creating an open and safe marketplace for traders, liquidity providers, and developers.</p>
      </div>
      <div class="section helper">
        <a class="helper_box" href="https://app.tinyman.org/#/swap" target="_blank" rel="noreferrer">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106488)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 4.5L12 0.5V3.5H1V5.5H12V8.5L16 4.5ZM4 8.5L0 12.5L4 16.5V13.5H15V11.5H4V8.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106488">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Swap on Tinyman
          </h5>
          <p>Trade Algorand Standard Assets easily on Tinyman</p>
        </a>
        <a class="helper_box" href="https://app.tinyman.org/#/pool" target="_blank" rel="noreferrer">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106503)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.8335 2.5H12.8335C13.6619 2.5 14.3335 3.17157 14.3335 4C14.3335 4.82843 13.6619 5.5 12.8335 5.5H3.8335C3.00507 5.5 2.3335 4.82843 2.3335 4C2.3335 3.17157 3.00507 2.5 3.8335 2.5ZM0.333496 4C0.333496 2.067 1.9005 0.5 3.8335 0.5H12.8335C14.7665 0.5 16.3335 2.067 16.3335 4C16.3335 5.933 14.7665 7.5 12.8335 7.5H3.8335C1.9005 7.5 0.333496 5.933 0.333496 4ZM3.8335 11.5H12.8335C13.6619 11.5 14.3335 12.1716 14.3335 13C14.3335 13.8284 13.6619 14.5 12.8335 14.5H3.8335C3.00507 14.5 2.3335 13.8284 2.3335 13C2.3335 12.1716 3.00507 11.5 3.8335 11.5ZM0.333496 13C0.333496 11.067 1.9005 9.5 3.8335 9.5H12.8335C14.7665 9.5 16.3335 11.067 16.3335 13C16.3335 14.933 14.7665 16.5 12.8335 16.5H3.8335C1.9005 16.5 0.333496 14.933 0.333496 13Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106503">
            <rect width="16" height="16" fill="white" transform="translate(0.333496 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Earn via Tinyman pools
          </h5>
          <p>Provide liquidity to Tinyman pools and earn fees on swaps.</p>
        </a> 
        <Link className="helper_box" to="/resources">
          <h5>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106514)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1665 0.5C3.50965 0.5 2.1665 1.84315 2.1665 3.5V13.5C2.1665 15.1569 3.50965 16.5 5.1665 16.5H14.1665H15.1665V14.5H14.6665V12.5H15.1665V10.5V2.5V1.5V0.5H5.1665ZM13.1665 2.5H5.1665C4.61422 2.5 4.1665 2.94772 4.1665 3.5V10.6707C4.47928 10.5602 4.81587 10.5 5.1665 10.5H13.1665V2.5ZM5.1665 12.5H12.6665V14.5H5.1665C4.61422 14.5 4.1665 14.0523 4.1665 13.5C4.1665 12.9477 4.61422 12.5 5.1665 12.5Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106514">
            <rect width="16" height="16" fill="white" transform="translate(0.666504 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Learn more about Tinyman
          </h5>
          <p>Learn about the core concepts of Tinyman and Algorand ecosystem</p>
        </Link> 
      </div>
      <div class="badge">
            <div class="badge_icon"></div>
            <div class="badge_text"></div>
          </div>
      <div class="section-s newversion">
          <h2>Tinyman v2 <br />designed for DeFi</h2>
          <p >Tinyman v2 is now live on Mainnet. New contracts, new features and new experience, specifically designed for your needs! Enjoy trading Algorand Standard Assets and providing liquidity with amazing fees. </p>
          <a href="https://app.tinyman.org/" class="mainnet_btn" target="_blank" rel="noreferrer">Go to Tinyman v2</a>
      </div>
      <div class="section helper migration">
        <a class="helper_box" href="https://medium.com/@tinymanorg/tinyman-launches-v2-contracts-big-migration-event-91d0ddb5f9c5" target="_blank" rel="noreferrer">
          <h5>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5549_106525)">
            <path d="M10.0999 4.90308L0.0605469 14.9425L1.47476 16.3567L11.5148 6.31665L11.5148 14.2168H13.5148L13.5148 2.90308L2.20109 2.90308L2.20109 4.90308L10.0999 4.90308Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_5549_106525">
            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
            </svg>
            Join the migration program
          </h5>
          <p>Tinyman is calling on all liquidity providers to move their liquidity with just a couple of easy transactions. Join the big migration now and discover the surprises waiting for you on the other side!</p>
        </a>
        <a class="helper_box teaser" href="https://youtu.be/l1-kPATIRhk" target="_blank" rel="noreferrer">
        </a>
      </div>

      <div class="section-s last">
        <h3>Our Roadmap</h3>
        <div class="roadmap_progress"></div>
        <ul class="list">
          <li>
            <h5>Q1 2024</h5>
            <p>Lending Pools & More Boosted Farms</p>
            <p>$TINY and Governance Audits</p>
          </li>
          <li>
            <h5>Q2 2024</h5>
            <p>Governance Forum Launch</p>
            <p>Airdrop Campaigns</p>
            <p>CEX Partnership Announcements</p>
          </li>
          <li>
            <h5>Q3 2024</h5>
            <p>$TINY Token & Governance Launch</p>
            <p>Farms V2 & Auto-Compounding</p>
            <p>Liquidity Mining Incentives</p>
            <p>More Bridged Assets & Pools</p>
          </li>
          <li>
            <h5>Q4 2024</h5>
            <p>Cross-Chain Expansion/Swap</p>
            <p>Decentralized Treasury Management</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IndexPage
